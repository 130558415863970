<template>
    <v-container fluid>
        <div class="d-flex mb-6" style="gap: 20px">
            <v-spacer />
            <v-btn @click.stop="moveDate(-1)" icon>
                <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
            <h2>{{ date.toLocaleDateString('esp',{month: "long"}).toUpperCase() }}</h2>
            <v-btn @click.stop="moveDate(1)" icon>
                <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
            <v-btn @click.stop="moveDate(-12)" icon>
                <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
            <h2>{{ date.toLocaleDateString('esp',{year: "numeric"}).toUpperCase() }}</h2>
            <v-btn @click.stop="moveDate(12)" icon>
                <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
            <v-spacer />
            <v-btn @click.stop="save()" color="primary">
                <v-icon>mdi-floppy</v-icon>
                GUARDAR
            </v-btn>
            
        </div>
        <v-card style="float:left" class="ma-5" width="35vw">
            <v-simple-table v-if="empleado && !loading" height="80vh">
                <thead style="position:sticky;top: 0;background-color: var(--v-secondary-base);z-index: 1000;">
                    <tr>
                        <th class="text-center" rowspan="2" style="font-size: large;border-bottom: 4px solid var(--v-secondary-base) !important;border-right: 4px solid var(--v-secondary-base) !important; color: var(--v-primary-base)">
                            DÍA
                        </th>
                        <th class="text-center" colspan="2" style="font-size: large;border-right: 4px solid var(--v-secondary-base) !important;border-left: 4px solid var(--v-secondary-base) !important; color: var(--v-primary-base)">EVENTO</th>
                        <th class="text-center" colspan="2" style="font-size: large;border-left: 4px solid var(--v-secondary-base) !important; color: var(--v-primary-base)">MONTAJE</th>
                    </tr>
                    <tr>
                        <th style="font-size: large; color: var(--v-primary-base);border-bottom: 4px solid var(--v-secondary-base) !important;border-left: 4px solid var(--v-secondary-base) !important" class="text-center">
                            HORAS (auto)
                        </th>
                        <th style="font-size: large; color: var(--v-primary-base);border-right: 4px solid var(--v-secondary-base) !important;border-bottom: 4px solid var(--v-secondary-base) !important;" class="text-center">
                            KMS (auto)
                        </th>
                        <th style="font-size: large; color: var(--v-primary-base);border-left: 4px solid var(--v-secondary-base) !important;border-bottom: 4px solid var(--v-secondary-base) !important;" class="text-center">
                            HORAS
                        </th>
                        <th style="font-size: large; color: var(--v-primary-base);border-bottom: 4px solid var(--v-secondary-base) !important;" class="text-center">
                            KMS
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <template v-for="d in days">
                        <tr>
                            <th class="text-center primary--text font-weight-black text-subtitle-1" style="cursor: pointer;border-top: 0 !important;border-right: 4px solid var(--v-secondary-base) !important" @click="$router.push({ path:`/horas/${date.getFullYear()}-${('0'+(date.getMonth()+1)).slice(-2)}-${('0'+d).slice(-2)}` })">
                                {{ d }}
                            </th>
                            <td style="border-left: 4px solid var(--v-secondary-base)">
                                <v-text-field @focus="$event.target.select()" :class="'centered-input ' + ( modelo.filter(mod => (mod.tipo == 'Ev' || mod.tipo == 'Top') && (new Date(mod.fecha + ' 12:00:00').toISOString().split('T')[0] == new Date(date.getUTCFullYear(), date.getUTCMonth(),d,12,0,0).toISOString().split('T')[0]))[0].horas != 0 ? 'blueInput' : 'grayInput')" dense hide-details="" v-model="modelo.filter(mod => (mod.tipo == 'Ev' || mod.tipo == 'Top') && (new Date(mod.fecha + ' 12:00:00').toISOString().split('T')[0] == new Date(date.getUTCFullYear(), date.getUTCMonth(),d,12,0,0).toISOString().split('T')[0]))[0].horas"></v-text-field>
                            </td>
                            <td style="border-right: 4px solid var(--v-secondary-base)">
                                <v-text-field @focus="$event.target.select()" :class="'centered-input ' + ( modelo.filter(mod => (mod.tipo == 'Ev' || mod.tipo == 'Top') && (new Date(mod.fecha + ' 12:00:00').toISOString().split('T')[0] == new Date(date.getUTCFullYear(), date.getUTCMonth(),d,12,0,0).toISOString().split('T')[0]))[0].kms != 0 ? 'blueInput' : 'grayInput')" dense hide-details="" v-model="modelo.filter(mod => (mod.tipo == 'Ev' || mod.tipo == 'Top') && (new Date(mod.fecha + ' 12:00:00').toISOString().split('T')[0] == new Date(date.getUTCFullYear(), date.getUTCMonth(),d,12,0,0).toISOString().split('T')[0]))[0].kms"></v-text-field>
                            </td>
                            <td style="border-left: 4px solid var(--v-secondary-base)">
                                <v-text-field @focus="$event.target.select()" :class="'centered-input ' + ( modelo.filter(mod => (mod.tipo == 'M') && (new Date(mod.fecha + ' 12:00:00').toISOString().split('T')[0] == new Date(date.getUTCFullYear(), date.getUTCMonth(),d,12,0,0).toISOString().split('T')[0]))[0].horas != 0 ? 'redInput' : 'grayInput')" dense hide-details="" v-model="modelo.filter(mod => (mod.tipo == 'M') && (new Date(mod.fecha + ' 12:00:00').toISOString().split('T')[0] == new Date(date.getUTCFullYear(), date.getUTCMonth(),d,12,0,0).toISOString().split('T')[0]))[0].horas"></v-text-field>
                            </td>
                            <td style="border-right: 1px solid var(--v-secondary-base)">
                                <v-text-field @focus="$event.target.select()" :class="'centered-input ' + ( modelo.filter(mod => (mod.tipo == 'M') && (new Date(mod.fecha + ' 12:00:00').toISOString().split('T')[0] == new Date(date.getUTCFullYear(), date.getUTCMonth(),d,12,0,0).toISOString().split('T')[0]))[0].kms != 0 ? 'redInput' : 'grayInput')" dense hide-details="" v-model="modelo.filter(mod => (mod.tipo == 'M') && (new Date(mod.fecha + ' 12:00:00').toISOString().split('T')[0] == new Date(date.getUTCFullYear(), date.getUTCMonth(),d,12,0,0).toISOString().split('T')[0]))[0].kms"></v-text-field>
                            </td>
                        </tr>
                    </template>
                </tbody>
            </v-simple-table>
        </v-card>
        <v-card style="min-width: 350px; float:left" class="ma-5">
            <v-simple-table v-if="empleado && !loading">
                <thead>
                    <tr>
                        <th style="border: 1px solid var(--v-secondary-base); border-bottom: 4px solid var(--v-secondary-base) !important;"></th>
                        <th style="color: var(--v-primary-base); font-size: large;border: 1px solid var(--v-secondary-base); border-bottom: 4px solid var(--v-secondary-base) !important;" class="text-left">
                            EQUIPO
                        </th>
                        <td style="border: 1px solid var(--v-secondary-base); border-bottom: 4px solid var(--v-secondary-base) !important;" class="text-center primary--text font-weight-black text-subtitle-1">
                            {{ empleado.nombre }} {{ empleado.inicialesApellidos || empleado.apellidos  }}
                        </td>
                    </tr>
                    <tr>
                        <th rowspan="4" style="border-top: 4px solid var(--v-secondary-base) !important;border-bottom: 4px solid var(--v-secondary-base) !important;writing-mode: vertical-rl;text-align: center;transform: rotate(180deg);font-size: large;color: var(--v-primary-base);height: 100px;">TOTALES (€)</th>
                        <th style="color: var(--v-primary-base); font-size: large;border-top: 4px solid var(--v-secondary-base) !important;border-bottom: 4px solid var(--v-secondary-base) !important;" class="text-left">
                            TOTAL NETO
                        </th>
                        <td style="color: var(--v-info-base);font-weight:bold;font-size: large;border-top: 4px solid var(--v-secondary-base) !important;border-bottom: 4px solid var(--v-secondary-base) !important;" class="text-right">
                            {{ calculate('neto') }} €
                        </td>
                    </tr>
                    <tr>
                        <th style="color: var(--v-primary-base); font-size: large;border-top: 4px solid var(--v-secondary-base) !important;" class="text-left">
                            EVENTOS
                        </th>
                        <td style="border-top: 4px solid var(--v-secondary-base) !important;" class="text-right">
                            {{ calculate('ev') }} €
                        </td>
                    </tr>
                    <tr>
                        <th style="color: var(--v-primary-base); font-size: large;" class="text-left">
                            MONTAJES
                        </th>
                        <td class="text-right">
                            {{ calculate('m') }} €
                        </td>
                    </tr>
                    <tr>
                        <th style="color: var(--v-primary-base); font-size: large;border-bottom: 4px solid var(--v-secondary-base) !important;" class="text-left">
                            KMS
                        </th>
                        <td style="border-bottom: 4px solid var(--v-secondary-base) !important;" class="text-right">
                            {{ calculate('costeKms') }} €
                        </td>
                    </tr>
                    <tr>
                        <th rowspan="3" style="border-bottom: 4px solid var(--v-secondary-base) !important;writing-mode: vertical-rl;text-align: center;transform: rotate(180deg);font-size: large;color: var(--v-primary-base);white-space: normal;height: 100px;">TOTALES (HORAS y KMS)</th>
                        <th style="color: var(--v-primary-base); font-size: large;border-top: 4px solid var(--v-secondary-base) !important;" class="text-left">
                            HS EVENTOS
                        </th>
                        <td style="border-top: 4px solid var(--v-secondary-base) !important;" class="text-right">
                            {{ calculate('hsEv') }}
                        </td>
                    </tr>
                    <tr>
                        <th style="color: var(--v-primary-base); font-size: large;" class="text-left">
                            HS MONTAJES
                        </th>
                        <td class="text-right">
                            {{ calculate('hsM') }}
                        </td>
                    </tr>
                    <tr>
                        <th style="color: var(--v-primary-base); font-size: large;" class="text-left">
                            KMS
                        </th>
                        <td class="text-right">
                            {{ calculate('kms') }}
                        </td>
                    </tr>
                </thead>
            </v-simple-table>
        </v-card>

    </v-container>
</template>

<script>

export default {
    props: {
        idEmpleado: String,
    },
    data(){
        return {
            date: new Date(new Date().getUTCFullYear(),new Date().getUTCMonth(),1,12,0,0),
            days: 0,
            modelo: [],
            varios: [],
            empleado:null,
            pag: 0,
            loading: true,
            saving: false,
        }
    },
    methods:{
        moveDate(m) {
            this.loading = true
            const date = new Date(this.date);
            date.setMonth(date.getMonth() + m);
            this.date = date
            this.days = new Date(this.date.getFullYear(), this.date.getMonth()+1,0,12,0,0).getDate()
            this.empleados = []
            this.modelo = []
            this.pag = 0
            this.getEmpleado()
        },
        calculate(r){
            var neto = 0
            
            var hsEv = 0
            var ev = 0

            var hsM = 0

            var kms = 0
            for (let d = 0; d < this.days; d++) {
                // console.log(ev);
                var tipo = this.modelo.filter(mod => (mod.tipo == 'Ev' || mod.tipo == 'Top')  && (new Date(mod.fecha + " 12:00:00").toISOString().split('T')[0] == new Date(this.date.getUTCFullYear(), this.date.getUTCMonth(),d+1,12,0,0).toISOString().split('T')[0]))[0].tipo
                
                if (tipo == 'Ev') {
                    hsEv += (parseFloat(this.modelo.filter(mod => (mod.tipo == 'Ev' || mod.tipo == 'Top')  && (new Date(mod.fecha + " 12:00:00").toISOString().split('T')[0] == new Date(this.date.getUTCFullYear(), this.date.getUTCMonth(),d+1,12,0,0).toISOString().split('T')[0]))[0].horas) || 0)
                    ev += parseFloat(this.modelo.filter(mod => (mod.tipo == 'Ev' || mod.tipo == 'Top')  && (new Date(mod.fecha + " 12:00:00").toISOString().split('T')[0] == new Date(this.date.getUTCFullYear(), this.date.getUTCMonth(),d+1,12,0,0).toISOString().split('T')[0]))[0].horas * this.empleado.pEvento)
                }
                else {
                    hsEv += (parseFloat(this.modelo.filter(mod => (mod.tipo == 'Ev' || mod.tipo == 'Top')  && (new Date(mod.fecha + " 12:00:00").toISOString().split('T')[0] == new Date(this.date.getUTCFullYear(), this.date.getUTCMonth(),d+1,12,0,0).toISOString().split('T')[0]))[0].horas) || 0)
                    ev += parseFloat(this.modelo.filter(mod => (mod.tipo == 'Ev' || mod.tipo == 'Top')  && (new Date(mod.fecha + " 12:00:00").toISOString().split('T')[0] == new Date(this.date.getUTCFullYear(), this.date.getUTCMonth(),d+1,12,0,0).toISOString().split('T')[0]))[0].horas * this.empleado.pEventoTop)
                }
                kms += (parseFloat(this.modelo.filter(mod => (mod.tipo == 'Ev' || mod.tipo == 'Top') && (new Date(mod.fecha + " 12:00:00").toISOString().split('T')[0] == new Date(this.date.getUTCFullYear(), this.date.getUTCMonth(),d+1,12,0,0).toISOString().split('T')[0]))[0].kms) || 0)
                kms += (parseFloat(this.modelo.filter(mod => (mod.tipo == 'M') && (new Date(mod.fecha + " 12:00:00").toISOString().split('T')[0] == new Date(this.date.getUTCFullYear(), this.date.getUTCMonth(),d+1,12,0,0).toISOString().split('T')[0]))[0].kms) || 0)

                hsM += (parseFloat(this.modelo.filter(mod => (mod.tipo == 'M')  && (new Date(mod.fecha + " 12:00:00").toISOString().split('T')[0] == new Date(this.date.getUTCFullYear(), this.date.getUTCMonth(),d+1,12,0,0).toISOString().split('T')[0]))[0].horas) || 0)
                ev += this.varios.filter(v => v.idUnidadEquipo == this.idEmpleado && (d+1) == this.date.getDate() && new Date(v.date).getFullYear() == this.date.getFullYear() && new Date(v.date).getMonth() == this.date.getMonth() && (v.pHoraAux == "Ev" || v.pHoraAux == "Top")).map(va => va.varios).reduce((acc,sum) => acc += sum,0)
            }
            neto = (ev + ((hsM * 8) + this.varios.filter(v => v.idUnidadEquipo == this.idEmpleado && new Date(v.date).getFullYear() == this.date.getFullYear() && new Date(v.date).getMonth() == this.date.getMonth() && v.pHoraAux == "M").map(va => va.varios).reduce((acc,sum) => acc += sum,0)) + (kms*.19))
            switch (r) {
                case 'neto':
                    return (neto).toFixed(2)
                case 'ev':
                    return (ev).toFixed(2)
                case 'm':
                    return (hsM * 8).toFixed(2)
                case 'costeKms':
                    return (kms * .19).toFixed(2)
                case 'hsEv':
                    return (hsEv).toFixed(2)
                case 'hsM':
                    return (hsM).toFixed(2)
                case 'kms':
                    return (kms).toFixed(2)
                default:
                    break;
            }
        },
        async save(){
            try{
                this.modelo.forEach(m => {
                    if (!m.horas) m.horas = 0
                    if (!m.kms) m.kms = 0
                });
                await Promise.all([
                    axios({
                        method: "post",
                        url: `/horas_mes/`,
                        data: {
                            modelo: JSON.stringify(this.modelo),
                            fecha: this.date,
                            idEmpleado: this.idEmpleado,
                        }
                    }),
                ]);
                this.$root.$emit("snack", "Se han guardado los cambios correctamente");
            }
            catch (err) {
                console.error(err);
                this.$root.$emit("snack", "No se han podido guardar los cambios");
            }
        },
        async getEmpleado(){
            const empleado = await axios({
                url: `/unidades_equipo/${this.idEmpleado}`
            });
            this.empleado = empleado.data;
            const { data } = await axios({
                url: `/horas_mes/empleado`,
                params: {
                    fecha: this.date,
                    idEmpleado: this.idEmpleado,
                },
            });
            this.modelo = JSON.parse(data);
            for(let d = 0; d < this.days; d++){
                if (this.modelo.filter(em => (em.tipo == 'Ev' || em.tipo == 'Top') && new Date(em.fecha + " 12:00:00").toISOString().split('T')[0] == new Date(this.date.getUTCFullYear(), this.date.getUTCMonth(),d+1,12,0,0).toISOString().split('T')[0]).length == 0){
                    this.modelo.push({
                        idUnidadEquipo : empleado.idUnidadEquipo,
                        fecha : new Date(this.date.getUTCFullYear(), this.date.getUTCMonth(),d+1,12,0,0).toISOString().split('T')[0],
                        horas : 0,
                        tipo : 'Ev',
                        kms: 0
                    })
                }
                if (this.modelo.filter(em => (em.tipo == 'M') && new Date(em.fecha + " 12:00:00").toISOString().split('T')[0] == new Date(this.date.getUTCFullYear(), this.date.getUTCMonth(),d+1,12,0,0).toISOString().split('T')[0]).length == 0){
                    this.modelo.push({
                        idUnidadEquipo : empleado.idUnidadEquipo,
                        fecha : new Date(this.date.getUTCFullYear(), this.date.getUTCMonth(),d+1,12,0,0).toISOString().split('T')[0],
                        horas : 0,
                        tipo : 'M',
                        kms: 0
                    })
                }
            }

            const varios = await axios({
                url: `/horas_mes/varios/${new Date(this.date.getUTCFullYear(), this.date.getUTCMonth(),1,12,0,0).toISOString().split('T')[0]}`
            });
            this.varios = varios.data

            this.loading = false;
        },
    },
    mounted(){
        this.days = new Date(this.date.getFullYear(), this.date.getMonth()+1,0,12,0,0).getDate()
        this.getEmpleado()
    }
};
</script>
<style scoped>
table tr th {
    border: 1px solid var(--v-secondary-base) !important;
    white-space: nowrap;
}
.centered-input >>> input {
    text-align: center;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}
table tr:nth-child(even){
    backdrop-filter: brightness(1.2);
}
.blueInput >>> input{
    font-weight: 900 !important;
    font-size: large !important;
    color: var(--v-info-base) !important
}
.blueInput :before {
    border-color: var(--v-info-base) !important;   
}
.redInput >>> input{
    font-weight: 900 !important;
    font-size: large !important;
    color: var(--v-error-base) !important
}
.redInput :before {
    border-color: var(--v-error-base) !important;   
}
.grayInput >>> input{
    color: #ffffff !important
}

.grayInput :before {
    border-color: #ffffff !important;   
}
</style>